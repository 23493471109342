import axios from 'axios';
import store from '../store/store';
import { VALIDATION_ERRORS } from '../actions/CommonActions';

const authServerAPI = axios.create({
  baseURL: window?.appConfig.REACT_APP_AUTH_SERVER_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

authServerAPI.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('auth_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

authServerAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    responseError(error);
  },
);

const imageAPI = axios.create({
  baseURL: window?.appConfig.REACT_APP_ISSUING_API,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

imageAPI.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

imageAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    responseError(error);
  },
);

const issuingAPI = axios.create({
  baseURL: window?.appConfig.REACT_APP_ISSUING_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

issuingAPI.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

issuingAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    responseError(error);
  },
);

const invoiceService = axios.create({
  baseURL: window?.appConfig.REACT_APP_INVOICE_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

invoiceService.interceptors.request.use(
  function (config) {
    if (!config.headers.token) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.token = token;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
const responseError = (error) => {
  if (error.response.status === 400) {
    console.log(error.response.data);
    throw new Error(error.response.data.message);
  }

  if (error.response.status === 403) {
    throw new Error(error.response.data.message);
  }

  if (error.response.status === 404) {
    throw new Error(error.response.data.message);
  }

  if (error.response.status === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('view');

    window.location.assign(`${window?.appConfig.REACT_APP_AUTH_SERVER_PATH}`);

    throw new Error(error.response.data.message);
  }

  if (error.response.status === 500) {
    throw new Error(error.response.data.message);
  }

  if (error.response.status === 422) {
    store.dispatch({ type: VALIDATION_ERRORS, payload: error.response.data });
    throw new Error(error.response.data.message);
  }

  return Promise.reject(error);
};

export default axios;

export { authServerAPI, issuingAPI, imageAPI, invoiceService };

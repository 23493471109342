import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchShopCountByStatus,
  fetchShops,
  RESET_SHOPS,
} from '../actions/Shops/ListOfShopsActions';
import { APP_COUNT } from '../actions/CommonActions';

function useShops({ defaultParams = {} }) {
  const dispatch = useDispatch();
  const { appCount } = useSelector((state) => state.commonReducer);
  const { data, loading, fetched, error, meta } = useSelector(
    (state) => state.listOfShops.shops,
  );

  const countByStatus = useSelector(
    (state) => state.listOfShops.countByStatus.data,
  );

  const fetch = useCallback(
    (params = {}) => {
      dispatch({ type: APP_COUNT });
      dispatch(fetchShops(params));
    },
    [fetchShops, dispatch],
  );

  useEffect(() => {
    if (appCount === 0) {
      dispatch({ type: RESET_SHOPS });
      fetch(defaultParams);
      dispatch(fetchShopCountByStatus());
    }
  }, [appCount, fetch, dispatch, defaultParams]);

  return {
    shops: data,
    fetch,
    isLoading: loading,
    isFetched: fetched,
    isError: error !== null,
    error,
    countByStatus,
    pagination: {
      isPaginated: meta.total > meta.per_page,
      currentPage: meta.current_page || 1,
      totalPages: meta.total / meta.per_page,
    },
  };
}

export { useShops };

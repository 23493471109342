import React, { useState, useMemo, useRef } from 'react';
import DefaultModal from '../../../Modal/DefaultModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendTicketByEmail } from '../../../../actions/LoadCardActions';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import { useReactToPrint } from 'react-to-print';
import Receipt from './Receipt';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import SuccessAlert from '../../../Alert/SuccessAlert';

export default function LoadCardPrintReceipt({ isOpen, onClose, loadCards }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { selectedLanguage } = useSelector((state) => state.language);
  const { sendingTicketViaEmail, emailSuccess, emailError } = useSelector(
    (state) => state.loadCard,
  );
  const componentRef = useRef();
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
  });

  const canSendReceiptByEmail = useMemo(
    () => checkLoggedInUserPermissions('send-ticket-via-email'),
    [checkLoggedInUserPermissions],
  );

  const sendEmail = () => {
    dispatch(
      sendTicketByEmail(email, loadCards.ticket_number, selectedLanguage),
    );
  };

  return (
    <DefaultModal isOpen={isOpen} onClose={onClose}>
      <>
        <div className="flex justify-between items-center divide-y">
          <div className="font-MulishBold">{t('gift-card-load-receipt')}</div>
        </div>
        {canSendReceiptByEmail && (
          <div>
            <div className="flex flex-col justify-start">
              <hr className="h-1 w-full my-2"></hr>
              <div className="mb-1 text-left">
                {t('Send the receipt by email')}
              </div>
              <div className="flex flex-row gap-5">
                <input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('type-in-email')}
                  autoComplete="false"
                  className="h-10 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                />
                <button
                  onClick={sendEmail}
                  className={`${
                    !canSendReceiptByEmail || email === ''
                      ? 'opacity-50 pointer-events-none '
                      : ''
                  } border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium`}
                >
                  {sendingTicketViaEmail && (
                    <ButtonLoading color={GF_DARK_BLUE} />
                  )}
                  {t(sendingTicketViaEmail ? 'Sending' : 'Send')}
                </button>
              </div>
              <div className={'mt-2'}>
                {emailSuccess && (
                  <SuccessAlert
                    title="Success"
                    message={`${t('Email-was-sent-successfully')} ${email}`}
                  />
                )}
                {emailError && <FailAlert message={emailError.message} />}
              </div>
            </div>
            <hr className="h-1 w-full my-8"></hr>
          </div>
        )}

        <div className="flex flex-col justify-between items-center space-x-5">
          <button
            onClick={printTicket}
            className="bg-gfCoral w-32 h-10 focus:outline-none rounded hover:bg-opacity-75 py-2 px-4 font-MulishBold"
          >
            {t('Print')}
          </button>
        </div>
        <div className={'h-56 overflow-y-scroll mt-5'}>
          <Receipt componentRef={componentRef} loads={loadCards} />
        </div>
      </>
    </DefaultModal>
  );
}

import React from 'react';
import { cn } from '../../utils';

const Table = ({ children }) => {
  return (
    <table className="min-w-full divide-y divide-gfPeriwinkle">
      {children}
    </table>
  );
};

Table.Head = ({ children }) => {
  return (
    <thead className="bg-gfGrey font-MulishBlack text-xs">{children}</thead>
  );
};

Table.Header = ({ children, className, ...props }) => {
  return (
    <th
      scope="col"
      className={cn(
        'text-center uppercase tracking-wider py-3 px-4',
        className,
      )}
      {...props}
    >
      {children}
    </th>
  );
};

Table.Body = ({ children, className, ...props }) => {
  return (
    <tbody
      className={cn('bg-white divide-y divide-gfPeriwinkle text-sm', className)}
      {...props}
    >
      {children}
    </tbody>
  );
};

Table.Row = ({ children, className, ...props }) => {
  return (
    <tr className={cn('px-4', className)} {...props}>
      {children}
    </tr>
  );
};

Table.Cell = ({ children, className, ...props }) => {
  return (
    <td className={cn('px-4 py-4 align-top text-center', className)} {...props}>
      {children}
    </td>
  );
};

export default Table;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultModal from './DefaultModal';
import PrepareCards from '../../modules/card/components/PrepareCards';
import OrderDetails from '../../modules/card/components/OrderDetails';

export default function OrderDetailsModal({
  isOpen,
  orderDetails,
  contentLabel,
  onClose,
  onPrepare,
}) {
  const { t } = useTranslation();
  const [isPreparing, setIsPreparing] = useState(false);

  if (!orderDetails) {
    return <></>;
  }

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={() => {
        setIsPreparing(false);
        onClose();
      }}
      classNames={isPreparing ? 'max-w-6xl' : ''}
      contentLabel={contentLabel}
    >
      {isPreparing ? (
        <PrepareCards
          contentLabel={t('Prepare cards')}
          orderDetails={orderDetails}
          onPrepare={onPrepare}
          onClose={() => {
            setIsPreparing(false);
            onClose();
          }}
        />
      ) : (
        <OrderDetails
          orderDetails={orderDetails}
          contentLabel={t('Order Details')}
          onPrepare={onPrepare}
          setIsPreparing={setIsPreparing}
          onClose={() => {
            setIsPreparing(false);
            onClose();
          }}
        />
      )}
    </DefaultModal>
  );
}

import React from 'react';
import Table from '../../../Table';
import Print from '../../../../assets/svg/Print';
import ChevronUp from '../../../../assets/svg/ChevronUp';
import ChevronDown from '../../../../assets/svg/ChevronDown';
import { Link } from 'react-router-dom';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

const canAccessCardInformation =
  checkLoggedInUserPermissions('card-information');

const getTokenToDisplay = (tokens) => {
  if (
    tokens.length === 1 &&
    (!tokens[0].token_number || tokens[0].token_number === '0')
  ) {
    return '';
  }

  return tokens.length === 1 && tokens[0].token_number ? (
    <Link
      className="underline"
      to={`/gift-cards/card-information?token=${tokens[0].token_number}`}
    >
      {tokens[0].token_number}
    </Link>
  ) : (
    `${tokens.length}x`
  );
};

export default function Row({
  setRowOpen,
  setOrderDetails,
  setIsOrderDetailsModalOpened,
  sale,
  rowIsOpen,
}) {
  return (
    <>
      <Table.Row key={sale.id}>
        <Table.Cell>{sale.date}</Table.Cell>
        <Table.Cell>{sale.ticket_number}</Table.Cell>
        <Table.Cell>{sale.channel ?? '-'}</Table.Cell>
        <Table.Cell>{getTokenToDisplay(sale.tokens)}</Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={sale.card_load_value_total} />
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={sale.card_load_fee_total} />
        </Table.Cell>
        <Table.Cell>
          <div>
            <ShoppingCenterMoney value={sale.additional_products_total} />
          </div>
        </Table.Cell>
        <Table.Cell>
          {sale.payments?.map((payment, index) => (
            <div key={payment.payment_method + index}>
              {`${payment.payment_method}`} -
              <ShoppingCenterMoney value={payment.amount} />
            </div>
          ))}
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={sale.payment_amount_total} />
        </Table.Cell>
        <Table.Cell className={'flex justify-center items-center'}>
          <button
            className="focus:outline-none mr-3"
            onClick={() => {
              setIsOrderDetailsModalOpened(true);
              setOrderDetails(sale);
            }}
          >
            <Print />
          </button>
          {sale.tokens.length > 1 ? (
            <button
              className="focus:outline-none"
              id={sale.ticket_number}
              onClick={() => setRowOpen(rowIsOpen ? null : sale.ticket_number)}
            >
              {rowIsOpen ? <ChevronUp /> : <ChevronDown />}
            </button>
          ) : (
            ''
          )}
        </Table.Cell>
      </Table.Row>
      {rowIsOpen &&
        sale.tokens.map((token) => (
          <Table.Row
            className={'bg-gray-100'}
            data-testid={token}
            key={token.token_number}
          >
            <Table.Cell data-testid="date"></Table.Cell>
            <Table.Cell data-testid="ticketNumber"></Table.Cell>
            <Table.Cell data-testid="user"></Table.Cell>
            <Table.Cell className={'flex'} data-testid="tokens">
              {!!token.token_number && token.token_number !== '0' && (
                <div className={'flex'}>
                  <Link
                    className="underline"
                    to={`/gift-cards/card-information?token=${token.token_number}`}
                  >
                    {token.token_number}
                  </Link>
                  <button
                    className="focus:outline-none ml-2"
                    onClick={() => {
                      setIsOrderDetailsModalOpened(true);
                      setOrderDetails({ token: token.token_number, ...sale });
                    }}
                  >
                    <Print />
                  </button>
                </div>
              )}
            </Table.Cell>
            <Table.Cell>
              <ShoppingCenterMoney value={token.amount} />
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        ))}
    </>
  );
}

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../UIComponents/Container';
import { useShops } from '../../../../hooks/useShop';
import { acceptanceStatus } from '../../../../enums/shop';
import Table from '../../../Table';
import ActiveIcon from '../../../../assets/svg/ActiveIcon';
import DeclineIcon from '../../../../assets/svg/DeclineIcon';
import PendingIcon from '../../../../assets/svg/PendingIcon';
import ViewTerminalsModal from './ViewTerminalsModal';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel } from '../../../Form';
import SelectField from '../../../Input/SelectField';
import useAppConfig from '../../../../hooks/useAppConfig';
import { Pagination } from '../../../Pagination';

function StatusIcon({ status, size }) {
  const iconProps = {
    w: size,
    h: size,
  };

  if (status === 'accepted') {
    return <ActiveIcon {...iconProps} />;
  }

  if (status === 'rejected') {
    return <DeclineIcon {...iconProps} />;
  }

  return <PendingIcon {...iconProps} />;
}

function Counter({ status, value }) {
  const { t } = useTranslation();

  const label = useMemo(
    () => t(`shops.acceptance_status.${status}`),
    [t, status],
  );

  return (
    <div className="flex flex-row items-center justify-start gap-x-2">
      <StatusIcon status={status} size={20} />
      <span className="uppercase">{label}:</span>
      <span className="text-xl">{value}</span>
    </div>
  );
}

function Row({ shop, onClickViewTerminals }) {
  const { t } = useTranslation();
  const { terminals } = shop;

  const hasMultipleTerminals = useMemo(() => terminals.length > 1, [terminals]);
  const terminalsText = useMemo(() => {
    if (terminals.length === 0) {
      return '-';
    }

    return terminals[0];
  }, [terminals]);

  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex items-center justify-center">
          <StatusIcon status={shop.status} size={20} />
        </div>
      </Table.Cell>
      <Table.Cell>{'-'}</Table.Cell>
      <Table.Cell>{shop.merchant?.name || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer.name || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer.mcc || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer.mcc_description || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer.creation_date || '-'}</Table.Cell>
      <Table.Cell>{shop.acquirer.modification_date || '-'}</Table.Cell>
      <Table.Cell>
        <span>{terminalsText}</span>
        {hasMultipleTerminals && (
          <>
            <span>, </span>
            <button type={'button'} onClick={() => onClickViewTerminals(shop)}>
              {t('More')}...
            </button>
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

function Filters({ onSubmit }) {
  const { t } = useTranslation();
  const form = useRef(null);
  const { handleSubmit } = useFormContext();

  const statusOptions = useMemo(
    () => [
      { value: null, label: t('shops.acceptance_status.all') },
      {
        value: acceptanceStatus.ACCEPTED,
        label: t('shops.acceptance_status.accepted'),
      },
      {
        value: acceptanceStatus.REJECTED,
        label: t('shops.acceptance_status.rejected'),
      },
      {
        value: acceptanceStatus.UNCONFIRMED,
        label: t('shops.acceptance_status.unconfirmed'),
      },
    ],
    [t],
  );

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-6"
      onSubmit={handleSubmit(onSubmit)}
      ref={form}
    >
      <FormField
        name="status"
        render={(field) => {
          const selectedOption = statusOptions.find(
            (option) => field.value === option.value,
          );

          return (
            <FormItem horizontal>
              <FormLabel>{t('shops.manage.filters.status')}:</FormLabel>
              <FormControl>
                <SelectField
                  options={statusOptions}
                  inputRef={field.ref}
                  value={selectedOption}
                  onChange={(option) => {
                    field.onChange(option.value);
                    form.current.requestSubmit();
                  }}
                  className="w-full"
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
    </form>
  );
}

export default function Page() {
  const { t } = useTranslation();
  const { getConfigValue } = useAppConfig();
  const perPage = getConfigValue('REACT_APP_PAGINATION_PER_PAGE_ROWS');
  const [terminalsForShopId, setTerminalsForShopId] = useState(null);
  const {
    shops,
    isLoading,
    isFetched,
    isError,
    error,
    fetch,
    pagination,
    countByStatus,
  } = useShops({
    defaultParams: {
      status: acceptanceStatus.UNCONFIRMED,
      page: 1,
      perPage,
    },
  });
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      status: acceptanceStatus.UNCONFIRMED,
    },
  });

  function onChangeFilters(data) {
    fetch({
      status: data.status,
      perPage,
    });
  }

  function onPaginate({ selected }) {
    const filters = formMethods.getValues();

    fetch({
      ...filters,
      perPage,
      page: selected + 1,
    });
  }

  const terminalsForShop = useMemo(() => {
    if (terminalsForShopId === null) {
      return null;
    }

    return shops.find((shop) => shop.id === terminalsForShopId);
  }, [shops, terminalsForShopId]);

  function onClickViewTerminals(shop) {
    setTerminalsForShopId(shop.id);
  }

  return (
    <Container title={t('shops.manage.title')} loading={isLoading}>
      <FormProvider {...formMethods}>
        <Filters onSubmit={onChangeFilters} />
        {isFetched && shops.length === 0 && (
          <MessageAlert message={t('shops.manage.no_results')} />
        )}
        {isError && <FailAlert message={error} />}
        {shops.length > 0 && (
          <div className="space-y-4">
            <div className="flex flex-row gap-x-12">
              {Object.keys(countByStatus).map((status) => (
                <Counter
                  key={status}
                  status={status}
                  value={countByStatus[status] || 0}
                />
              ))}
            </div>
            {pagination.isPaginated && (
              <div className="flex flex-col md:flex-row md:justify-end w-full">
                <Pagination
                  pageCount={pagination.totalPages}
                  onPageChange={onPaginate}
                />
              </div>
            )}
            <div className="shadow overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border">
              <Table>
                <Table.Head>
                  <tr>
                    <Table.Header>
                      {t('shops.manage.results.status')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.location')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.name')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.acquirer_name')}
                    </Table.Header>
                    <Table.Header>{t('shops.manage.results.mcc')}</Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.mcc_description')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.creation_date')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.last_modification_date')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.terminals')}
                    </Table.Header>
                    <Table.Header>
                      {t('shops.manage.results.actions')}
                    </Table.Header>
                  </tr>
                </Table.Head>
                <Table.Body>
                  {shops.map((shop) => (
                    <Row
                      key={shop.id}
                      shop={shop}
                      onClickViewTerminals={onClickViewTerminals}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
        {terminalsForShop && (
          <ViewTerminalsModal
            shop={terminalsForShop}
            onClose={() => setTerminalsForShopId(null)}
          />
        )}
      </FormProvider>
    </Container>
  );
}

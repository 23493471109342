import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Table from '../../../Table';
import { useTranslation } from 'react-i18next';
import Container from '../../../UIComponents/Container';
import { format } from 'date-fns';
import SummariesByChannel from './SummariesByChannel';
import SummaryModal from './SummaryModal';
import Row from './Row';
import Summaries from './Summaries';
import Filter from '../Filter';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';
import AlertHandler from '../../../Alert/AlertHandler';

const columns = [
  'Date',
  'Transaction-number',
  'channel',
  'Token',
  'Card-load',
  'card-load-fee',
  'Additional-product',
  'Payment-method',
  'Payment-amount',
  'Action',
];

export default function AllSales() {
  const { t } = useTranslation();
  const [rowOpen, setRowOpen] = useState(null);
  const [isOrderDetailsModalOpened, setIsOrderDetailsModalOpened] =
    useState(false);
  const [filter, setFilter] = useState({});
  const [orderDetails, setOrderDetails] = useState(false);

  useEffect(() => {
    getSalesList({});
  }, []);

  const [allSalesHandler, getAllSales] = useApiHook(reportAPI.getAllSales);
  const [allSalesSummaryHandler, getAllSalesSummary] = useApiHook(
    reportAPI.getAllSalesSummary,
  );

  const pageCount = useMemo(() => {
    return (
      allSalesHandler.data?.data?.length >
      window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS
    );
  }, [allSalesHandler.data?.data?.length]);

  const [excelReport, getExcelReport] = useApiHook(
    reportAPI.getExcelReportSales,
  );

  const getSalesList = useCallback(
    ({ fromDate = null, toDate = null, channel = null }) => {
      const params = {
        from_date: format(fromDate ?? new Date(), 'yyyy-MM-dd'),
        to_date: format(toDate ?? new Date(), 'yyyy-MM-dd'),
      };

      if (channel && channel.id !== -1) {
        params.channel_id = channel.id;
      }

      getAllSales(params);
      getAllSalesSummary(params);
    },
    [],
  );

  return (
    <Container title={t('report-all-sales')}>
      <Filter
        onFilter={(data) => {
          getSalesList(data);
          setFilter(data);
        }}
        status={allSalesHandler}
      />
      {!!allSalesSummaryHandler?.data &&
        !!allSalesHandler.data?.data?.length && (
          <Summaries summary={allSalesSummaryHandler.data} />
        )}
      {!!allSalesSummaryHandler?.data?.channel_summary?.length && (
        <SummariesByChannel
          summary={allSalesSummaryHandler.data.channel_summary}
        />
      )}
      {!!allSalesHandler.data?.data?.length && allSalesHandler.success && (
        <div className={'flex justify-between mb-3'}>
          <>
            <button
              className={
                'px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold'
              }
              onClick={() => getExcelReport(filter)}
            >
              {t('export-excel')}
            </button>
            {pageCount && (
              <div>
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </>
        </div>
      )}

      {!!allSalesHandler.data?.data?.length && allSalesHandler.success && (
        <Table>
          <Table.Head>
            <Table.Row>
              {columns.map((column, index) => (
                <Table.Header key={column + index}>{t(column)}</Table.Header>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allSalesHandler?.data?.data?.map((sale) => (
              <Row
                key={sale.ticket_number}
                sale={sale}
                rowIsOpen={rowOpen === sale.ticket_number}
                setRowOpen={setRowOpen}
                setOrderDetails={setOrderDetails}
                setIsOrderDetailsModalOpened={setIsOrderDetailsModalOpened}
              />
            ))}
          </Table.Body>
        </Table>
      )}
      <AlertHandler handler={allSalesHandler} />

      <SummaryModal
        ticket={orderDetails}
        isOpen={isOrderDetailsModalOpened}
        onClose={() => setIsOrderDetailsModalOpened(false)}
      />
    </Container>
  );
}

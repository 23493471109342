import {
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS,
  RESET_SHOPS,
  FETCH_SHOPS_ERROR,
  FETCH_SHOPS_COUNT_SUCCESS,
} from '../../actions/Shops/ListOfShopsActions';

const initialState = {
  shops: {
    data: [],
    meta: {},
    loading: false,
    fetched: false,
    error: null,
  },
  countByStatus: {
    data: {
      accepted: 0,
      unconfirmed: 0,
      rejected: 0,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SHOPS:
      return {
        ...state,
        shops: {
          ...state.shops,
          loading: true,
        },
      };
    case FETCH_SHOPS_SUCCESS:
      return {
        ...state,
        shops: {
          data: action.payload.data,
          meta: action.payload.meta,
          loading: false,
          fetched: true,
          error: null,
        },
      };
    case FETCH_SHOPS_ERROR:
      return {
        ...state,
        shops: {
          ...state.shops,
          meta: {},
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_SHOPS_COUNT_SUCCESS:
      return {
        ...state,
        countByStatus: {
          ...state.countByStatus,
          data: action.payload,
        },
      };
    case RESET_SHOPS:
      return {
        ...state,
        shops: initialState.shops,
        countByStatus: initialState.countByStatus,
      };
    default:
      return state;
  }
}

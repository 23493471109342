import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeManageSalesStatus,
  getManageSalesByDeliveryMethod,
  prepareAndActivateOrder,
  RESET_ACTIVATE_ORDER,
  RESET_ORDER_STATUS,
} from '../../../../actions/ManageSaleActions';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';

import FailAlert from '../../../Alert/FailAlert';
import SuccessAlert from '../../../Alert/SuccessAlert';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import notFound from '../../../../assets/images/products/default.png';
import ValidationErrors from '../../../Alert/ValidationErrors';
import { RESET_VALIDATION_ERRORS } from '../../../../actions/CommonActions';

ReactModal.setAppElement('#root');

export default function PrepareOrder({
  isOpen,
  data,
  closeHandler,
  selectedOrder,
  selectedCustomerType,
  selectedCarrier = null,
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm();
  const dispatch = useDispatch();

  const [prepareOrderView, setPrepareOrderView] = useState(false);

  const {
    changingOrderStatus,
    successOrderStatus,
    failOrderStatus,
    successActivateOrder,
    failActivateOrder,
  } = useSelector((state) => state.manageSales);
  const { validationErrors } = useSelector((state) => state.commonReducer);

  const prepareOrder = () => {
    setPrepareOrderView(true);
  };

  const prepareActivateOrder = () => {
    setPrepareOrderView(true);
  };

  const onSubmit = (collection) => {
    dispatch({ type: RESET_ACTIVATE_ORDER });
    dispatch({ type: RESET_ORDER_STATUS });
    dispatch({ type: RESET_VALIDATION_ERRORS });
    const tokens = [];

    for (const property in collection) {
      tokens.push({ id: property, token: collection[property] });
    }

    if (data.home_delivery === 1 && data.status === 'paid') {
      dispatch(changeManageSalesStatus(data.reference, tokens, 'prepared'));
    }

    if (data.home_delivery === 0 && data.status === 'paid') {
      dispatch(prepareAndActivateOrder(data.reference, tokens, 'prepared'));
    }
    reset();
  };

  const onError = (event, id) => {
    event.currentTarget.src = notFound;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-lg rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center ">
          <div className="font-MulishBold">
            {`${t('Order-information').toUpperCase()} - ${data.reference}`}
          </div>
          <button
            id="closeButton"
            className="focus:outline-none"
            onClick={() => {
              closeHandler(!isOpen);
              setPrepareOrderView(false);
              dispatch({ type: RESET_ACTIVATE_ORDER });
              dispatch({ type: RESET_ORDER_STATUS });
              dispatch({ type: RESET_VALIDATION_ERRORS });

              if (
                data.home_delivery === 1 &&
                data.status === 'paid' &&
                successOrderStatus
              ) {
                dispatch(
                  getManageSalesByDeliveryMethod(
                    selectedOrder.homeDeliver,
                    selectedOrder.value,
                    selectedCustomerType.value,
                    '',
                    0,
                    null,
                    null,
                    selectedCarrier?.value,
                  ),
                );
              }

              if (
                data.home_delivery === 0 &&
                data.status === 'paid' &&
                successActivateOrder
              ) {
                dispatch(
                  getManageSalesByDeliveryMethod(
                    selectedOrder.homeDeliver,
                    selectedOrder.value,
                    selectedCustomerType.value,
                    '',
                    0,
                    null,
                    null,
                    selectedCarrier?.value,
                  ),
                );
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>
        {!prepareOrderView && (
          <div className="flex flex-col my-3">
            <div className="py-2 mb-3">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Order-Recap')}`}</div>
              <div className="flex flex-row">
                <span className="w-40 text-left font-MulishBold">{`${t('Amount')} :`}</span>
                <div className="flex justify-center">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={data.total_amount}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span className="ml-2">{` ${data.currency}`}</span>
                </div>
              </div>

              <div className="flex flex-row">
                <span className="w-40 text-left font-MulishBold">{`${t('Fee')} :`}</span>
                <div className="flex justify-center">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={data.fee_amount}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span className="ml-2">{` ${data.currency}`}</span>
                </div>
              </div>

              <div className="flex flex-row">
                <span className="w-40 text-left font-MulishBold">{`${t('Status')} :`}</span>
                <span className="">{String(data.status).toUpperCase()}</span>
              </div>
            </div>
            <div className="py-2 mb-3">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Purchaser-Information')}`}</div>
              <div className="flex flex-row">
                <span className="w-40 text-left font-MulishBold">{`${t('Name')} :`}</span>
                <span>{`${data.first_name} ${data.last_name}`}</span>
              </div>
              <div className="flex flex-row">
                <span className="w-40 text-left font-MulishBold">{`${t('Email-address')} :`}</span>
                <span>{data.email}</span>
              </div>
              {data && data.phone_number !== '' && (
                <div className="flex flex-row">
                  <span className="w-40 text-left font-MulishBold">{`${t('Phone-number')} :`}</span>
                  <span>{data.phone_number}</span>
                </div>
              )}
            </div>

            <div className="py-2 mb-3">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Delivery-Information')}`}</div>
              {data && data.address !== '' && (
                <div className="flex flex-row items-start justify-start">
                  <span className="w-40 text-left font-MulishBold">{`${t('Address')} :`}</span>
                  <div className="flex flex-col text-left">
                    {data.recipient_name && <span>{data.recipient_name}</span>}
                    {data.company_name && <span>{data.company_name}</span>}
                    {data.address && <span>{data.address}</span>}
                    <div className="inline-block">
                      {data.zipcode && (
                        <span className="mr-1">{data.zipcode}</span>
                      )}
                      {data.city && <span className="mr-1">{data.city}</span>}
                    </div>
                    {data.country_name && <span>{`${data.country_name}`}</span>}
                    {data.phone_number && <span>{data.phone_number}</span>}
                  </div>
                </div>
              )}
              {[0, 1].includes(data?.home_delivery) && (
                <div className="flex flex-row items-start justify-start">
                  <span className="w-40 text-left font-MulishBold">{`${t('Delivery-method')} :`}</span>
                  <div className="flex flex-col text-left">
                    {data.home_delivery === 0 && (
                      <span>
                        {t('Pick-up')}
                        {data?.carrier_name ? ` (${data.carrier_name})` : ''}
                      </span>
                    )}
                    {data.home_delivery === 1 && (
                      <span>
                        {t('Home-delivery')}
                        {data?.carrier_name ? ` (${data.carrier_name})` : ''}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="py-2 mb-3">
              <div className=" flex justify-start items-center w-full font-MulishBlack mb-3">{`${t('Cards')}`}</div>

              <div className="border rounded">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-xs font-normal w-1/2 py-1 px-2 text-left">{`${t('Card')}`}</th>
                      <th className="text-xs font-normal w-1/2 py-1 px-2 text-right">{`${t('Amount')}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.customers &&
                      data.customers.map((token) => (
                        <tr key={token.id}>
                          <td className="font-MulishBold text-left px-2">
                            {data.quantity}
                          </td>
                          <td className="text-right  px-2">
                            <div className="flex justify-end font-MulishBold ">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={token.amount_loaded}
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{` ${data.currency}`}</span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            {data.status === 'awaiting_payment' && (
              <div className="flex justify-center items-center w-full py-3">
                {data.home_delivery === 1 && data.status === 'paid' && (
                  <button
                    id="prepareOderButton"
                    onClick={prepareOrder}
                    className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                  >
                    {t('Prepare-order')}
                  </button>
                )}

                {data.home_delivery === 0 && data.status === 'paid' && (
                  <button
                    id="prepareAndActivateButton"
                    onClick={prepareActivateOrder}
                    className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                  >
                    {t('Prepare-and-Activate')}
                  </button>
                )}
              </div>
            )}

            {data.status === 'paid' && (
              <div className="flex justify-center items-center w-full py-3">
                {data.home_delivery === 1 && data.status === 'paid' && (
                  <button
                    id="prepareOderButton"
                    onClick={prepareOrder}
                    className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                  >
                    {t('Prepare-order')}
                  </button>
                )}

                {data.home_delivery === 0 && data.status === 'paid' && (
                  <button
                    id="prepareAndActivateButton"
                    onClick={prepareActivateOrder}
                    className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                  >
                    {t('Prepare-and-Activate')}
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {prepareOrderView && (
          <div className="flex flex-col my-3">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              {data.customers &&
                data.customers.map((token) => (
                  <div
                    key={token.id}
                    className="flex flex-row justify-between items-center py-3 border-b"
                  >
                    {/* <div className="w-32 h-24 border rounded flex justify-center items-center border-gfPeriwinkle">
                                        <img
                                            id={token.id}
                                            className="modalImage"
                                            src={window?.appConfig.REACT_APP_IMAGE_URL + "/" + token.product.image}
                                            onError={(event) => onError(event, token.id)}
                                            alt=""
                                        />
                                    </div> */}

                    <div className="flex flex-col">
                      {/* <div className="text-sm py-1">{token.product.name}</div> */}
                      <input
                        className="w-48 border font-MulishBold  text-center rounded h-8 focus:outline-none border-gfPeriwinkle"
                        name={`${token.id}`}
                        ref={register({ required: true })}
                        placeholder={t('Token')}
                        maxLength="9"
                      />
                      {errors[`${token.id}`] && (
                        <p className="text-xs text-gfCoral p-1">
                          {t('Required')}
                        </p>
                      )}
                    </div>

                    <div className="flex justify-end text-gfLightBlue font-MulishBold">
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={token.amount_loaded}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span className="ml-2">{` ${data.currency}`}</span>
                    </div>
                  </div>
                ))}

              <div className="py-4 space-y-5">
                {data.customers.length === 0 && (
                  <div className="text-base font-MulishBold">
                    Token List is Empty
                  </div>
                )}
                <button
                  id="confirmCardsButton"
                  type="submit"
                  className={` ${data.customers.length === 0 ? 'hidden' : ''} bg-gfCoral w-full focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold`}
                >
                  {changingOrderStatus && (
                    <ButtonLoading color={GF_DARK_BLUE} />
                  )}
                  {t('Confirm-the-cards-preparation')}
                </button>

                {successOrderStatus && (
                  <SuccessAlert
                    title={t('Done')}
                    message={t('Successfully-confirmed')}
                  />
                )}
                {failOrderStatus && (
                  <FailAlert message={failOrderStatus.message} />
                )}
                {successActivateOrder && (
                  <SuccessAlert
                    title={t('Done')}
                    message={t('Successfully-confirmed')}
                  />
                )}
                {failActivateOrder && (
                  <FailAlert message={failActivateOrder.message} />
                )}
                {validationErrors && (
                  <div className="py-2">
                    <ValidationErrors {...validationErrors} />
                  </div>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </ReactModal>
  );
}

import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { issuingAPI } from '../../services/Axios';

const PREFIX = '@listOfShops/';

export const RESET_SHOPS = `${PREFIX}RESET_SHOPS`;
export const FETCH_SHOPS = `${PREFIX}FETCH_SHOPS`;
export const FETCH_SHOPS_SUCCESS = `${PREFIX}FETCH_SHOPS_SUCCESS`;
export const FETCH_SHOPS_ERROR = `${PREFIX}FETCH_SHOPS_ERROR`;

export const FETCH_SHOPS_COUNT_SUCCESS = `${PREFIX}FETCH_SHOPS_COUNT_SUCCESS`;
export const FETCH_SHOPS_COUNT_ERROR = `${PREFIX}FETCH_SHOPS_COUNT_ERROR`;

export const fetchShopCountByStatus = () => async (dispatch, getState) => {
  const { tagCardBrands, tagID } = getState().tag;
  const params = {
    card_brand_ids: tagCardBrands,
  };

  try {
    const response = await issuingAPI.get(`/tag/${tagID}/shop/get-count`, {
      params,
    });

    dispatch({
      type: FETCH_SHOPS_COUNT_SUCCESS,
      payload: mapValues(keyBy(response.data, 'status'), 'count'),
    });
  } catch (error) {
    dispatch({ type: FETCH_SHOPS_COUNT_ERROR, payload: error });
  }
};

export const fetchShops =
  (filters = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_SHOPS });

    const { tagID, tagCardBrands } = getState().tag;

    let params = {
      per_page: filters.perPage,
      card_brand_ids: tagCardBrands,
    };

    if (filters.status) {
      params = {
        ...params,
        shop_status: filters.status,
      };
    }

    if (filters.page) {
      params = {
        ...params,
        page: filters.page,
      };
    }

    try {
      const response = await issuingAPI.get(`/tag/${tagID}/shop`, { params });

      const shops = response.data.data.map((item) => ({
        id: item.id,
        merchant: null,
        acquirer: {
          name: item.name,
          mcc: null,
          mcc_description: item.shop_category,
          creation_date: item.card_brands[0].status_changed_date,
          modification_date: item.card_brands[0].status_changed_date,
        },
        terminals: item.terminals.flatMap((terminal) =>
          terminal.devices.flatMap((device) => device.serial_number),
        ),
        status: item.card_brands[0].status,
      }));

      dispatch({
        type: FETCH_SHOPS_SUCCESS,
        payload: {
          data: shops,
          meta: response.data.meta,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_SHOPS_ERROR, payload: error });
    }
  };

import React from 'react';
import { cn } from '../../utils';

export function InputGroup({ children, className }) {
  return <div className={cn('relative', className)}>{children}</div>;
}

export function InputGroupText({ children, position }) {
  return (
    <div
      className={cn(
        'pointer-events-none absolute inset-y-0 flex items-center',
        {
          'pl-3 left-0': position === 'left',
          'pr-3 right-0': position === 'right',
        },
      )}
    >
      <span>{children}</span>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import CardInfoWidget from '../../../CardInfoWidget';

export default function Summaries({ summary }) {
  const { t } = useTranslation();

  return (
    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-20">
      <CardInfoWidget
        bottomLabel={t('Number-of-transactions')}
        isCurrency={false}
        value={summary.transactions_total}
        labelColor={'text-gfDarkBlue'}
        valueColor={'text-gfDarkBlue'}
      />
      <CardInfoWidget
        bottomLabel={t('Total-amount')}
        isCurrency={true}
        value={summary.total_amount}
        labelColor={'text-gfDarkBlue'}
        valueColor={'text-gfDarkBlue'}
      />
    </div>
  );
}

import { issuingAPI } from '../services/Axios';
import { APP_COUNT } from './CommonActions';

export const PREFIX = '@usersList/';

export const LOADING_USERS_LIST = `${PREFIX}LOADING_USERS_LIST`;
export const RECEIVED_USERS_LIST = `${PREFIX}RECEIVED_USERS_LIST`;
export const ERROR_USERS_LIST = `${PREFIX}ERROR_USERS_LIST`;
export const RESET_USERS_LIST = `${PREFIX}RESET_USERS_LIST`;

export const getUsersList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_USERS_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: APP_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/user?super_admin=1`)
    .then((success) => {
      dispatch({ type: RECEIVED_USERS_LIST, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_USERS_LIST, payload: error });
    });
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormControl, FormField, FormItem, FormMessage } from '../../Form';
import { InputGroup, InputGroupText } from '../../InputGroup';
import AmountField from '../../Input/AmountField';

function PurchaseFeeField({}) {
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  return (
    <FormField
      name="purchase_fee"
      defaultValue={selectedTag.purchase_fee.amount_with_vat}
      rules={{
        required: t('place-b2b-order.required'),
        min: {
          value: selectedTag.purchase_fee.min_amount ?? 0,
          message: t('validation.between.money', {
            min: selectedTag.purchase_fee.min_amount ?? 0,
            max: selectedTag.purchase_fee.max_amount ?? 999999,
            currency: tagCurrency,
            attribute: t('place-b2b-order.purchase-fee.line-item.name'),
          }),
        },
        max: {
          value: selectedTag.purchase_fee.max_amount ?? 999999,
          message: t('validation.between.money', {
            min: selectedTag.purchase_fee.min_amount ?? 0,
            max: selectedTag.purchase_fee.max_amount ?? 999999,
            currency: tagCurrency,
            attribute: t('place-b2b-order.purchase-fee.line-item.name'),
          }),
        },
      }}
      render={(field) => (
        <FormItem>
          <InputGroup className={'w-32'}>
            <FormControl>
              <AmountField
                {...field}
                placeholder={`100.00`}
                type="number"
                className="text-right pr-12"
              />
            </FormControl>
            <InputGroupText position="right">{tagCurrency}</InputGroupText>
          </InputGroup>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default PurchaseFeeField;

import { issuingAPI } from '../services/Axios';
import store from '../store/store';
import { format } from 'date-fns';
import { exportExcelFile } from '../utils/Helper';

const getAllSales = async (params) => {
  const { selectedTag } = store.getState().tag;

  return issuingAPI.get(`tag/${selectedTag.id}/all-sales`, { params });
};

const getAllSalesSummary = async (params) => {
  const { selectedTag } = store.getState().tag;

  return issuingAPI.get(`tag/${selectedTag.id}/all-sales/summary`, { params });
};

const getExcelReportSales = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    from_date: format(params.fromDate ?? new Date(), 'yyyy-MM-dd'),
    to_date: format(params.toDate ?? new Date(), 'yyyy-MM-dd'),
    tag: selectedTag.name,
    channel: params.channel?.id ?? null,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/all-sales/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'SalesSummary-Sale';
      const fileName = `${reportName}-${params.fromDate}-${params.toDate}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

export default {
  getAllSales,
  getAllSalesSummary,
  getExcelReportSales,
};

import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DefaultReceipt,
  ReceiptField,
  ReceiptSection,
  ReceiptTitle,
} from '../../../Receipt';
import Money from '../../../Money';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

export default forwardRef(function Receipt({ ticket }, ref) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  if (!ticket) {
    return <></>;
  }

  return (
    <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
      <DefaultReceipt ref={ref}>
        <>
          {ticket.user_name !== null && (
            <ReceiptSection>
              <ReceiptField name={t('User')} value={ticket.user_name} />
            </ReceiptSection>
          )}

          <ReceiptSection>
            <ReceiptField name={t('Date-of-Purchase')} value={ticket.date} />
          </ReceiptSection>

          {!ticket.token && (
            <ReceiptSection>
              <ReceiptField
                name={t('Ticket-number')}
                value={ticket.ticket_number}
              />
            </ReceiptSection>
          )}
          {!ticket.token && ticket.payments && (
            <>
              <ReceiptTitle title={t('Payment-methods')} />

              {ticket.payments.map(({ amount, payment_method }) => (
                <ReceiptField
                  name={payment_method}
                  value={<Money value={amount} />}
                />
              ))}
            </>
          )}

          <ReceiptTitle title={t('Gift-Cards')} />
          {ticket.tokens &&
            ticket.tokens
              .filter(
                ({ token_number }) =>
                  !ticket?.token || ticket.token === token_number,
              )
              .map(({ token_number, amount }) => {
                return (
                  <ReceiptSection key={token_number}>
                    <>
                      <ReceiptField name={t('Card')} value={token_number} />
                      <ReceiptField
                        name={t('Amount')}
                        value={
                          <ShoppingCenterMoney
                            value={amount}
                            currency={tagCurrency}
                          />
                        }
                      />
                    </>
                  </ReceiptSection>
                );
              })}
          {!ticket.token && (
            <>
              <ReceiptTitle title={t('Order-Recap')} />
              <ReceiptField name={t('Cards')} value={ticket.tokens.length} />
              <ReceiptField
                name={t('Total-amount')}
                value={
                  <ShoppingCenterMoney value={ticket.card_load_value_total} />
                }
              />
              <ReceiptField
                name={t('Total-fees')}
                value={
                  <ShoppingCenterMoney value={ticket.card_load_fee_total} />
                }
              />
              <ReceiptField
                name={t('Additional-products')}
                value={
                  <ShoppingCenterMoney
                    value={ticket.additional_products_total}
                  />
                }
              />
              <ReceiptField
                name={t('payment-amount-total')}
                value={
                  <ShoppingCenterMoney value={ticket.payment_amount_total} />
                }
              />
            </>
          )}
        </>
      </DefaultReceipt>
    </div>
  );
});

import React, { useEffect } from 'react';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../utils/Constants';
import PageTitle from '../PageTitle';

export default function Container({ children, loading, title }) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} - myPortal`;

    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <div className="shadow-md text-gfDarkBlue font-MulishRegular rounded mx-1 sm:mx-1 md:mx-1 lg:mx-3 xl:mx-5 2xl:mx-20 bg-white">
      <div className="flex flex-row items-center justify-start">
        <PageTitle title={title} />
        {loading && <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>}
      </div>
      <div className="border-t border-gfPeriwinkle px-4 py-5">
        <div className="flex flex-col mx-auto sm:mx-auto md:mx-auto lg:mx-10 xl:mx-10 2xl:mx-10 ">
          {children}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../Form';
import DatePickerField from '../../Input/DatePickerField';
import { addDays, isAfter } from 'date-fns';
import ChannelListDropdown from '../../Dropdown/ChannelListDropdown';
import Button from '../../UIComponents/Button';
import { cn } from '../../../utils';
import { useForm } from 'react-hook-form';
import channelAPI from '../../../api/channelAPI';
import useApiHook from '../../../hooks/useAPI';

const allChannels = { name: 'All', value: null };

export default function Filter({ onFilter }) {
  const { selectedTag } = useSelector((state) => state.tag);

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      fromDate: new Date(),
      toDate: null,
      channel: null,
    },
  });
  const [handler, getChannelListCall] = useApiHook(channelAPI.getChannelList);

  useEffect(() => {
    getChannelList();
  }, []);

  const optionsToDisplay = useMemo(() => {
    return handler.data ? [allChannels, ...handler.data.data] : [allChannels];
  }, [handler.data]);

  useEffect(() => {
    methods.setValue(
      'channel',
      optionsToDisplay.length ? optionsToDisplay[0] : null,
    );
  }, [optionsToDisplay]);

  const getChannelList = () => {
    if (!selectedTag.card_brand_ids.length) {
      return;
    }
    getChannelListCall();
  };

  const { t } = useTranslation();
  const fromDate = methods.watch('fromDate');

  return (
    <Form {...methods}>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 items-end mb-4">
        <div className="flex flex-col">
          <FormField
            name="fromDate"
            render={(field) => (
              <FormItem>
                <FormControl>
                  <DatePickerField
                    {...field}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    excludeDateIntervals={[
                      {
                        start: new Date(),
                        end: addDays(new Date(), 1),
                      },
                    ]}
                    label={t('Choose-the-date')}
                    maxDate={new Date()}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col">
          <FormField
            name="toDate"
            rules={{
              validate: (value) => !value || isAfter(value, fromDate),
            }}
            render={(field) => (
              <FormItem>
                <FormControl>
                  <DatePickerField
                    {...field}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    label={`${t('End-date')}(${t('Optional')})`}
                    maxDate={new Date()}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="flex flex-col w-full">
          <div className="flex items-center  py-1">{`${t('Channel')} :`}</div>
          <FormField
            name="channel"
            defaultValue={optionsToDisplay.length ? optionsToDisplay[0] : null}
            render={(field) => (
              <FormItem>
                <FormControl>
                  <ChannelListDropdown
                    {...field}
                    options={optionsToDisplay}
                    onChangeHandler={(event) => field.onChange(event)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button
          label={t('Go')}
          loading={
            methods.formState.isSubmitting ||
            methods.formState.isValidating ||
            handler.loading
          }
          onClick={methods.handleSubmit((data) => onFilter({ ...data }))}
          classAddsName={cn(
            'h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 font-MulishBold bg-gfCoral px-4 focus:outline-none',
            methods.formState.isValid && !handler.loading
              ? ''
              : 'opacity-50 pointer-events-none',
          )}
        />
      </div>
    </Form>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Userpilot } from 'userpilot';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Container from '../../UIComponents/Container';
import { getPaymentMethods } from '../../../actions/LoadCardActions';
import {
  addB2BOrder,
  RESET_B2B_ORDER,
} from '../../../actions/PlaceB2BOrderActions';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../Form';
import { Step, Steps } from '../../Step';
import TextField from '../../Input/TextField';
import CloseIcon from '../../../assets/svg/CloseIcon';
import SuccessAlert from '../../Alert/SuccessAlert';
import ValidationErrors from '../../Alert/ValidationErrors';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../utils/Constants';
import { RESET_VALIDATION_ERRORS } from '../../../actions/CommonActions';
import AddGiftCardForm from './AddGiftCardForm';
import FailAlert from '../../Alert/FailAlert';
import Money from '../../Money';
import PaymentMethodField from './PaymentMethodField';
import { Card, CardFooter, CardTitle } from '../../Card';
import PurchaseFeeField from './PurchaseFeeField';
import useOrder from '../../../hooks/useOrder';

const OrderSummary = ({
  cards,
  totalAmount,
  lineItems,
  totalPurchaseFeeAmount,
  totalCardsQuantity,
}) => {
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <Card data-testid="orderSummary">
      <CardTitle>{t('place-b2b-order.order-details')}</CardTitle>
      {cards.fields.map((card, index) => (
        <div key={card.id} hidden>
          <Controller
            name={`cards.${index}.quantity`}
            defaultValue={card.quantity}
            render={(field) => <input type="hidden" {...field} />}
          />
          <Controller
            name={`cards.${index}.amount`}
            defaultValue={card.amount}
            render={(field) => <input type="hidden" {...field} />}
          />
        </div>
      ))}
      <div className="flex flex-col space-y-1">
        {lineItems.map((lineItem, index) => (
          <div
            data-testid={`row_${index}`}
            key={index}
            className="flex flex-row justify-between items-center"
          >
            <div>{lineItem.name}</div>
            <div className="flex flex-row space-x-2">
              <div>
                <Money value={lineItem.total} currency={tagCurrency} />
              </div>
              <button type="button" onClick={() => lineItem.onRemove(index)}>
                <CloseIcon w={16} h={17} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {selectedTag.purchase_fee_enabled && (
        <div className="flex flex-col space-y-1">
          <div className="flex flex-row justify-between items-center">
            <div>{`${t('place-b2b-order.purchase-fee.line-item.name')} (x${totalCardsQuantity})`}</div>
            <div className="flex flex-row space-x-2">
              <div>
                <Money
                  className={'pr-6'}
                  value={totalPurchaseFeeAmount}
                  currency={tagCurrency}
                />
              </div>
            </div>
          </div>
          <span className={'text-sm'}>({t('VAT included')})</span>
        </div>
      )}
      <CardFooter
        dataTestID={t('place-b2b-order.total')}
        className="font-MulishBold mr-6"
      >
        {t('place-b2b-order.total')}:{' '}
        <Money value={totalAmount} currency={tagCurrency} />
      </CardFooter>
    </Card>
  );
};

const defaultValues = {
  cards: [],
  paymentMethod: [{ method_id: '', amount: 0 }],
  purchaser: {
    company_name: '',
    customer_id: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
  additional_comment: '',
};

export default function PlaceB2BOrders() {
  const { t } = useTranslation();
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const dispatch = useDispatch();
  const { successB2BOrder } = useSelector((state) => state.placeB2BOrder);
  const { validationErrors, appCount } = useSelector(
    (state) => state.commonReducer,
  );
  const [checkAmounts, setCheckAmounts] = useState(false);
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const cards = useFieldArray({ name: 'cards', control: methods.control });
  const paymentMethod = useFieldArray({
    name: 'paymentMethod',
    control: methods.control,
  });
  const { isValid, isSubmitting } = methods.formState;
  const onRemoveCard = useCallback((index) => cards.remove(index), [cards]);
  const {
    totalAmount,
    paymentTotalAmount,
    hasPurchaseFee,
    lineItems,
    totalPurchaseFeeAmount,
    totalCardsQuantity,
  } = useOrder({
    cards: methods.watch('cards', []),
    purchaseFee: methods.watch('purchase_fee', 0),
    paymentMethods: methods.watch('paymentMethod'),
    onRemoveCard,
  });

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) {
      dispatch(getPaymentMethods());
    }
  }, []);

  const isSuccessful = useMemo(
    () => successB2BOrder !== null,
    [successB2BOrder],
  );
  const { reset } = methods;

  useEffect(() => {
    if (isSuccessful) {
      reset();
      setTimeout(() => {
        dispatch({ type: RESET_B2B_ORDER });
      }, 3000);
    }
  }, [isSuccessful, reset, dispatch]);

  function onSubmit(data) {
    dispatch({ type: RESET_VALIDATION_ERRORS });

    if (data.paymentMethod.length > 1 && totalAmount !== paymentTotalAmount) {
      setCheckAmounts(true);
      return;
    }

    const payload = {
      ...data,
    };

    if (payload.purchase_fee) {
      payload.purchase_fee = parseFloat(payload.purchase_fee).toFixed(2);
    }

    setCheckAmounts(false);

    if (payload.paymentMethod.length === 1) {
      payload.paymentMethod[0].amount = totalAmount;
    }

    return dispatch(addB2BOrder(payload));
  }

  function onAddCard({ amount, quantity }) {
    const fieldIndex = cards.fields.findIndex(
      (field) => field.amount === amount,
    );

    if (fieldIndex >= 0) {
      const existingQuantity = methods.getValues().cards[fieldIndex].quantity;

      methods.setValue(
        `cards.${fieldIndex}.quantity`,
        existingQuantity + quantity,
      );

      return;
    }

    cards.append({ amount, quantity });
  }

  return (
    <Container title={t('place-b2b-order.place-b2b-order')}>
      <Form {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="grid grid-cols-4 gap-x-4"
        >
          <Steps className="col-span-full sm:col-span-3 xl:col-span-2 flex flex-col">
            <div className="mb-4">
              {isSuccessful && (
                <SuccessAlert
                  title={t('place-b2b-order.success.title')}
                  message={t('place-b2b-order.success.description')}
                />
              )}
              {validationErrors && <ValidationErrors {...validationErrors} />}
              {checkAmounts && (
                <FailAlert
                  message={t(
                    'The-input-amount-is-does-not-correspond-to-the-total-of-the-purchase',
                    {
                      amount: totalAmount,
                      currency: tagCurrency,
                    },
                  )}
                />
              )}
            </div>
            <Step title={t('place-b2b-order.add-your-gift-cards')} required>
              <AddGiftCardForm onSuccess={onAddCard} />
            </Step>
            {hasPurchaseFee && (
              <Step
                title={`${t('place-b2b-order.purchase-fee.title')} (${t('place-b2b-order.purchase-fee.line-item.description')})`}
                required
              >
                <PurchaseFeeField />
              </Step>
            )}
            <div className="flex flex-col">
              <Step
                title={t('place-b2b-order.enter-customer-information')}
                required
              >
                <div className="grid grid-cols-2 gap-4">
                  <FormField
                    name="purchaser.first_name"
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.first-name')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="purchaser.last_name"
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.last-name')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="purchaser.company_name"
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.company-name')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="purchaser.customer_id"
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.customer-id')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="purchaser.phone"
                    rules={{ required: t('place-b2b-order.required') }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.phone-number')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="purchaser.email"
                    rules={{
                      required: t('place-b2b-order.required'),
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    }}
                    defaultValue={''}
                    render={(field) => (
                      <FormItem>
                        <FormControl>
                          <TextField
                            type="text"
                            {...field}
                            placeholder={t('place-b2b-order.email-address')}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </Step>
              <Step title={t('place-b2b-order.select-payment-method')} required>
                <PaymentMethodField paymentMethod={paymentMethod} />
              </Step>
              <Step title={t('place-b2b-order.comments')} isLast={true}>
                <FormField
                  name="additional_comment"
                  defaultValue={''}
                  render={(field) => (
                    <FormItem>
                      <FormControl>
                        <textarea
                          data-testid="additional_comment"
                          {...field}
                          className="rounded-md border border-gfPeriwinkle p-2 focus:outline-none focus:border-gfPeriwinkle"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Step>
            </div>
          </Steps>
          <div className="flex flex-col justify-between col-start-4">
            <div>
              {cards.fields?.length !== 0 && (
                <OrderSummary
                  cards={cards}
                  totalAmount={totalAmount}
                  totalCardsQuantity={totalCardsQuantity}
                  lineItems={lineItems}
                  totalPurchaseFeeAmount={totalPurchaseFeeAmount}
                />
              )}
            </div>

            <div className="flex flex-row justify-end gap-5 mt-20">
              <button
                data-testid="formSubmitButton"
                type="submit"
                disabled={isSubmitting || !isValid || cards.fields.length === 0}
                className={`${!isValid || cards.fields.length === 0 ? 'bg-opacity-50' : 'bg-opacity-100'} bg-gfCoral flex flex-row rounded hover:bg-opacity-50 px-4 py-2 font-MulishBold`}
              >
                {isSubmitting && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('place-b2b-order.place-order')}
              </button>
              <button
                data-testid="formResetButton"
                onClick={() => {
                  setCheckAmounts(false);
                  dispatch({ type: RESET_B2B_ORDER });
                  dispatch({ type: RESET_VALIDATION_ERRORS });
                  reset();
                }}
                type="button"
                className="bg-gfPeriwinkle rounded hover:bg-opacity-50 px-4 py-2 font-MulishBold"
              >
                {t('place-b2b-order.reset')}
              </button>
            </div>
          </div>
        </form>
      </Form>
    </Container>
  );
}

import React from 'react';
import FailAlert from './FailAlert';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import MessageAlert from './MessageAlert';
import { useTranslation } from 'react-i18next';

export default function AlertHandler({ handler }) {
  const { t } = useTranslation();
  return (
    <>
      {handler.error && <FailAlert message={handler.error} />}
      {handler.loading && (
        <div className={'flex justify-center mt-6'}>
          <ButtonLoading />
          <p>{t('Loading-data')}...</p>
        </div>
      )}
      {handler.success && !handler?.data?.data?.length && (
        <MessageAlert
          className={'mt-6'}
          message={t('There-are-no-information-to-display-for-this-date')}
        />
      )}
    </>
  );
}
